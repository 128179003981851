import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import ScanningStep from '../../containers/scanning-step.jsx';
import AutoScanningStep from '../../containers/auto-scanning-step.jsx';
import ConnectingStep from './connecting-step.jsx';
import ConnectedStep from './connected-step.jsx';
import ErrorStep from './error-step.jsx';
import UnavailableStep from './unavailable-step.jsx';

import AutoScanningUsbStep from '../../containers/auto-scanning-usb-step.jsx';
import AutoScanningWebusbStep from '../../containers/auto-scanning-webusb-step.jsx';

import ConnectingUsbStep from './connecting-usb-step.jsx';
import ConnectingWebusbStep from './connecting-webusb-step.jsx';

import ConnectedUsbStep from './connected-usb-step.jsx';
import ConnectedWebusbStep from './connected-webusb-step.jsx';

import UnavailableUsbStep from './unavailable-usb-step.jsx';
import UnavailableWebusbStep from './unavailable-webusb-step.jsx';

import styles from './connection-modal.css';

const PHASES = keyMirror({
    scanning: null,
    connecting: null,
    connected: null,
    error: null,
    unavailable: null
});

function isSupportWebBluetooth(extensionId) {
    //记录UseLink的值
    // if (jQuery('#checkbox_useLink').length > 0
    //     && jQuery('#checkbox_useLink').prop('checked') === true
    if(window.CookieManager.getCookie('useLink')==='true'
        || window.preferences && window.preferences.value.useLink===true
    ) {
        window.Extensions[extensionId].useLink = true;
    } else {
        window.Extensions[extensionId].useLink = false;
    }

    //如果勾选了就强制禁用Web Bluetooth
    if (window.Extensions[extensionId].useLink === true) {
        return false;
    }

    if (!navigator.bluetooth) {
        return false;
    }
    return true;
}

const ConnectionModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        headerImage={props.connectionSmallIconURL}
        id="connectionModal"
        onHelp={props.onHelp}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
            {props.phase === PHASES.scanning && !props.useAutoScan && window.Extensions[props.extensionId].type==='ble' && isSupportWebBluetooth(props.extensionId) && <AutoScanningStep {...props} />}
            {props.phase === PHASES.scanning && !props.useAutoScan && (window.Extensions[props.extensionId].type!=='ble' || !isSupportWebBluetooth(props.extensionId)) && <ScanningStep {...props} />}
            {props.phase === PHASES.scanning && props.useAutoScan && props.extensionId.toLowerCase().includes('webusb') && <AutoScanningWebusbStep {...props} />}
            {props.phase === PHASES.scanning && props.useAutoScan && !props.extensionId.toLowerCase().includes('webusb') && props.extensionId.toLowerCase().includes('usb') && <AutoScanningUsbStep {...props} />}
            {props.phase === PHASES.scanning && props.useAutoScan && !props.extensionId.toLowerCase().includes('usb') && <AutoScanningStep {...props} />}

            {props.phase === PHASES.connecting && props.extensionId.toLowerCase().includes('webusb') && <ConnectingWebusbStep {...props} />}
            {props.phase === PHASES.connecting && !props.extensionId.toLowerCase().includes('webusb') && props.extensionId.toLowerCase().includes('usb') && <ConnectingUsbStep {...props} />}
            {props.phase === PHASES.connecting && !props.extensionId.toLowerCase().includes('usb') && <ConnectingStep {...props} />}

            {props.phase === PHASES.connected && props.extensionId.toLowerCase().includes('webusb') && <ConnectedWebusbStep {...props} />}
            {props.phase === PHASES.connected && !props.extensionId.toLowerCase().includes('webusb') && props.extensionId.toLowerCase().includes('usb') && <ConnectedUsbStep {...props} />}
            {props.phase === PHASES.connected && !props.extensionId.toLowerCase().includes('usb') && <ConnectedStep {...props} />}

            {props.phase === PHASES.error && <ErrorStep {...props} />}

            {props.phase === PHASES.unavailable && props.extensionId.toLowerCase().includes('webusb') && <UnavailableWebusbStep {...props} />}
            {props.phase === PHASES.unavailable && !props.extensionId.toLowerCase().includes('webusb') && props.extensionId.toLowerCase().includes('usb') && <UnavailableUsbStep {...props} />}
            {props.phase === PHASES.unavailable && !props.extensionId.toLowerCase().includes('usb') && <UnavailableStep {...props} />}
        </Box>
    </Modal>
);

ConnectionModalComponent.propTypes = {
    connectingMessage: PropTypes.node.isRequired,
    connectionSmallIconURL: PropTypes.string,
    connectionTipIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired,
    useAutoScan: PropTypes.bool.isRequired
};

ConnectionModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    ConnectionModalComponent as default,
    PHASES
};
