import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import superbotIconURL from './superbot/superbot.png';
import superbotInsetIconURL from './superbot/superbot-small.png';
import superbotConnectionIconURL from './superbot/superbot-illustration.svg';
import superbotConnectionSmallIconURL from './superbot/superbot-small.png';
import superbotConnectionTipIconURL from './superbot/superbot-button-illustration.gif';

import robotmasterIconURL from './robotmaster/robotmaster.png';
import robotmasterInsetIconURL from './robotmaster/robotmaster-small.png';
import robotmasterConnectionIconURL from './robotmaster/robotmaster-illustration.svg';
import robotmasterConnectionSmallIconURL from './robotmaster/robotmaster-small.png';
import robotmasterConnectionTipIconURL from './robotmaster/robotmaster-button-illustration.gif';

import robotmasterUsbIconURL from './robotmasterUsb/robotmasterUsb.png';
import robotmasterUsbInsetIconURL from './robotmasterUsb/robotmasterUsb-small.png';
import robotmasterUsbConnectionIconURL from './robotmasterUsb/robotmasterUsb-illustration.svg';
import robotmasterUsbConnectionSmallIconURL from './robotmasterUsb/robotmasterUsb-small.png';
import robotmasterUsbConnectionTipIconURL from './robotmasterUsb/robotmasterUsb-button-illustration.gif';

import robotmasterTournamentBleIconURL from './robotmasterTournamentBle/default.png';
import robotmasterTournamentBleInsetIconURL from './robotmasterTournamentBle/small.png';
import robotmasterTournamentBleConnectionIconURL from './robotmasterTournamentBle/illustration.svg';
import robotmasterTournamentBleConnectionSmallIconURL from './robotmasterTournamentBle/small.png';
import robotmasterTournamentBleConnectionTipIconURL from './robotmasterTournamentBle/button-illustration.gif';

import robotmasterTournamentUsbIconURL from './robotmasterTournamentUsb/robotmasterTournamentUsb.png';
import robotmasterTournamentUsbInsetIconURL from './robotmasterTournamentUsb/robotmasterTournamentUsb-small.png';
import robotmasterTournamentUsbConnectionIconURL from './robotmasterTournamentUsb/robotmasterTournamentUsb-illustration.svg';
import robotmasterTournamentUsbConnectionSmallIconURL from './robotmasterTournamentUsb/robotmasterTournamentUsb-small.png';
import robotmasterTournamentUsbConnectionTipIconURL from './robotmasterTournamentUsb/robotmasterTournamentUsb-button-illustration.gif';

import mithonccWebusbIconURL from './mithonccWebusb/mithonccwebusb.png';
import mithonccWebusbInsetIconURL from './mithonccWebusb/mithonccwebusb-small.png';
import mithonccWebusbConnectionIconURL from './mithonccWebusb/mithonccwebusb-illustration.svg';
import mithonccWebusbConnectionSmallIconURL from './mithonccWebusb/mithonccwebusb-small.png';
import mithonccWebusbConnectionTipIconURL from './mithonccWebusb/mithonccwebusb-button-illustration.gif';

import mithonccBluetoothIconURL from './mithonccBluetooth/mithonccBluetooth.png';
import mithonccBluetoothInsetIconURL from './mithonccBluetooth/mithonccBluetooth-small.png';
import mithonccBluetoothConnectionIconURL from './mithonccBluetooth/mithonccBluetooth-illustration.svg';
import mithonccBluetoothConnectionSmallIconURL from './mithonccBluetooth/mithonccBluetooth-small.png';
import mithonccBluetoothConnectionTipIconURL from './mithonccBluetooth/mithonccBluetooth-button-illustration.gif';

import icbBleIconURL from './icbBle/icbBle.png';
import icbBleInsetIconURL from './icbBle/icbBle-small.png';
import icbBleConnectionIconURL from './icbBle/icbBle-illustration.svg';
import icbBleConnectionSmallIconURL from './icbBle/icbBle-small.png';
import icbBleConnectionTipIconURL from './icbBle/icbBle-button-illustration.gif';

import icbUsbIconURL from './icbUsb/icbUsb.png';
import icbUsbInsetIconURL from './icbUsb/icbUsb-small.png';
import icbUsbConnectionIconURL from './icbUsb/icbUsb-illustration.svg';
import icbUsbConnectionSmallIconURL from './icbUsb/icbUsb-small.png';
import icbUsbConnectionTipIconURL from './icbUsb/icbUsb-button-illustration.gif';

import gibbonIconURL from './gibbon/gibbon.jpg';
import gibbonInsetIconURL from './gibbon/gibbon-small.png';
import gibbonConnectionIconURL from './gibbon/gibbon-illustration.svg';
import gibbonConnectionSmallIconURL from './gibbon/gibbon-small.png';
import gibbonConnectionTipIconURL from './gibbon/gibbon-button-illustration.gif';

import icb02UsbIconURL from './icb02Usb/icb02Usb.jpg';
import icb02UsbInsetIconURL from './icb02Usb/icb02Usb-small.png';
import icb02UsbConnectionIconURL from './icb02Usb/icb02Usb-illustration.svg';
import icb02UsbConnectionSmallIconURL from './icb02Usb/icb02Usb-small.png';
import icb02UsbConnectionTipIconURL from './icb02Usb/icb02Usb-button-illustration.gif';

import superbotMiniBleIconURL from './superbotMiniBle/default.jpg';
import superbotMiniBleInsetIconURL from './superbotMiniBle/small.png';
import superbotMiniBleConnectionIconURL from './superbotMiniBle/illustration.svg';
import superbotMiniBleConnectionSmallIconURL from './superbotMiniBle/small.png';
import superbotMiniBleConnectionTipIconURL from './superbotMiniBle/button-illustration.gif';

import superbotMiniUsbIconURL from './superbotMiniUsb/default.jpg';
import superbotMiniUsbInsetIconURL from './superbotMiniUsb/small.png';
import superbotMiniUsbConnectionIconURL from './superbotMiniUsb/illustration.svg';
import superbotMiniUsbConnectionSmallIconURL from './superbotMiniUsb/small.png';
import superbotMiniUsbConnectionTipIconURL from './superbotMiniUsb/button-illustration.gif';

export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Text to Speech"
    //             description="Name for the Text to Speech extension"
    //             id="gui.extension.text2speech.name"
    //         />
    //     ),
    //     extensionId: 'text2speech',
    //     collaborator: 'Amazon Web Services',
    //     iconURL: text2speechIconURL,
    //     insetIconURL: text2speechInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Make your projects talk."
    //             description="Description for the Text to speech extension"
    //             id="gui.extension.text2speech.description"
    //         />
    //     ),
    //     featured: true,
    //     internetConnectionRequired: true
    // },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Translate"
    //             description="Name for the Translate extension"
    //             id="gui.extension.translate.name"
    //         />
    //     ),
    //     extensionId: 'translate',
    //     collaborator: 'Google',
    //     iconURL: translateIconURL,
    //     insetIconURL: translateInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Translate text into many languages."
    //             description="Description for the Translate extension"
    //             id="gui.extension.translate.description"
    //         />
    //     ),
    //     featured: true,
    //     internetConnectionRequired: true
    // },
    {
        name: (
            <FormattedMessage
                defaultMessage="RobotMaster Bluetooth Mode"
                description="Name for the 'Robotmaster' extension"
                id="gui.extension.robotmaster.name"
            />
        ),
        extensionId: 'robotmaster',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'Robotmaster' extension"
                id="gui.extension.robotmaster.collaborator"
            />
        ),
        iconURL: robotmasterIconURL,
        insetIconURL: robotmasterInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything you want."
                description="Description for the 'Robotmaster' extension"
                id="gui.extension.robotmaster.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: robotmasterConnectionIconURL,
        connectionSmallIconURL: robotmasterConnectionSmallIconURL,
        connectionTipIconURL: robotmasterConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Robotmaster."
                id="gui.extension.robotmaster.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="RobotMaster USB Mode"
                description="Name for the 'RobotmasterUsb' extension"
                id="gui.extension.robotmasterUsb.name"
            />
        ),
        extensionId: 'robotmasterUsb',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'RobotmasterUsb' extension"
                id="gui.extension.robotmasterUsb.collaborator"
            />
        ),
        iconURL: robotmasterUsbIconURL,
        insetIconURL: robotmasterUsbInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A robot that can be controlled by connecting a USB cable."
                description="Description for the 'RobotmasterUsb' extension"
                id="gui.extension.robotmasterUsb.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: robotmasterUsbConnectionIconURL,
        connectionSmallIconURL: robotmasterUsbConnectionSmallIconURL,
        connectionTipIconURL: robotmasterUsbConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="正在连接"
                description="Message to help people connect to their RobotmasterUsb."
                id="gui.extension.robotmasterUsb.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Robotmaster Tournament (Bluetooth Mode)"
                description="Name for the 'RobotmasterTournamentBle' extension"
                id="gui.extension.robotmasterTournamentBle.name"
            />
        ),
        extensionId: 'robotmasterTournamentBle',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'RobotmasterTournamentBle' extension"
                id="gui.extension.robotmasterTournamentBle.collaborator"
            />
        ),
        iconURL: robotmasterTournamentBleIconURL,
        insetIconURL: robotmasterTournamentBleInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A robot that can be controlled by a Bluetooth LE connection."
                description="Description for the 'RobotmasterTournamentBle' extension"
                id="gui.extension.robotmasterTournamentBle.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: robotmasterTournamentBleConnectionIconURL,
        connectionSmallIconURL: robotmasterTournamentBleConnectionSmallIconURL,
        connectionTipIconURL: robotmasterTournamentBleConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their RobotmasterTournamentBle."
                id="gui.extension.robotmasterTournamentBle.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="RobotMaster Tournament (USB Mode)"
                description="Name for the 'RobotmasterTournamentUsb' extension"
                id="gui.extension.robotmasterTournamentUsb.name"
            />
        ),
        extensionId: 'robotmasterTournamentUsb',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'RobotmasterTournamentUsb' extension"
                id="gui.extension.robotmasterTournamentUsb.collaborator"
            />
        ),
        iconURL: robotmasterTournamentUsbIconURL,
        insetIconURL: robotmasterTournamentUsbInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A robot that can be controlled by connecting a USB cable."
                description="Description for the 'RobotmasterTournamentUsb' extension"
                id="gui.extension.robotmasterTournamentUsb.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: robotmasterTournamentUsbConnectionIconURL,
        connectionSmallIconURL: robotmasterTournamentUsbConnectionSmallIconURL,
        connectionTipIconURL: robotmasterTournamentUsbConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their RobotmasterTournamentUsb."
                id="gui.extension.robotmasterTournamentUsb.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="SuperBot"
                description="Name for the 'Superbot' extension"
                id="gui.extension.superbot.name"
            />
        ),
        extensionId: 'superbot',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'Superbot' extension"
                id="gui.extension.superbot.collaborator"
            />
        ),
        iconURL: superbotIconURL,
        insetIconURL: superbotInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="The integrative robot"
                description="Description for the 'Superbot' extension"
                id="gui.extension.superbot.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: superbotConnectionIconURL,
        connectionSmallIconURL: superbotConnectionSmallIconURL,
        connectionTipIconURL: superbotConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Superbot."
                id="gui.extension.superbot.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Mithon_CC"
    //             description="Name for the 'Mithon_CC' extension"
    //             id="gui.extension.mithonccWebusb.name"
    //         />
    //     ),
    //     extensionId: 'mithonccWebusb',
    //     collaborator: (
    //         <FormattedMessage
    //             defaultMessage="齐道机器人"
    //             description="Collaborator for the 'Mithon_CC' extension"
    //             id="gui.extension.mithonccWebusb.collaborator"
    //         />
    //     ),
    //     iconURL: mithonccWebusbIconURL,
    //     insetIconURL: mithonccWebusbInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="一体化的机器人"
    //             description="Description for the 'Mithon_CC' extension"
    //             id="gui.extension.mithonccWebusb.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: true,
    //     connectionIconURL: mithonccWebusbConnectionIconURL,
    //     connectionSmallIconURL: mithonccWebusbConnectionSmallIconURL,
    //     connectionTipIconURL: mithonccWebusbConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="正在连接"
    //             description="Message to help people connect to their Mithon_CC."
    //             id="gui.extension.mithonccWebusb.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://www.mixio.org'
    // },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="蓝牙连接的Mithon_CC"
    //             description="Name for the 'Mithon_CC with Bluetooth' extension"
    //             id="gui.extension.mithonccBluetooth.name"
    //         />
    //     ),
    //     extensionId: 'mithonccBluetooth',
    //     collaborator: (
    //         <FormattedMessage
    //             defaultMessage="齐道机器人"
    //             description="Collaborator for the 'Mithon_CC with Bluetooth' extension"
    //             id="gui.extension.mithonccBluetooth.collaborator"
    //         />
    //     ),
    //     iconURL: mithonccBluetoothIconURL,
    //     insetIconURL: mithonccBluetoothInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="一体化的机器人"
    //             description="Description for the 'Mithon_CC with Bluetooth' extension"
    //             id="gui.extension.mithonccBluetooth.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: false,
    //     connectionIconURL: mithonccBluetoothConnectionIconURL,
    //     connectionSmallIconURL: mithonccBluetoothConnectionSmallIconURL,
    //     connectionTipIconURL: mithonccBluetoothConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="正在连接"
    //             description="Message to help people connect to their Mithon_CC with Bluetooth."
    //             id="gui.extension.mithonccBluetooth.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://www.mixio.org'
    // },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="智控板（低功耗蓝牙模式）"
    //             description="Name for the 'icbBle' extension"
    //             id="gui.extension.icbBle.name"
    //         />
    //     ),
    //     extensionId: 'icbBle',
    //     collaborator: (
    //         <FormattedMessage
    //             defaultMessage="途道机器人"
    //             description="Collaborator for the 'icbBle' extension"
    //             id="gui.extension.icbBle.collaborator"
    //         />
    //     ),
    //     iconURL: icbBleIconURL,
    //     insetIconURL: icbBleInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="做你想做的机器人"
    //             description="Description for the 'icbBle' extension"
    //             id="gui.extension.icbBle.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: false,
    //     connectionIconURL: icbBleConnectionIconURL,
    //     connectionSmallIconURL: icbBleConnectionSmallIconURL,
    //     connectionTipIconURL: icbBleConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="正在连接"
    //             description="Message to help people connect to their Robotmaster."
    //             id="gui.extension.icbBle.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://www.makerzoid.com/'
    // },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="智控板（USB模式）"
    //             description="Name for the 'icbUsb' extension"
    //             id="gui.extension.icbUsb.name"
    //         />
    //     ),
    //     extensionId: 'icbUsb',
    //     collaborator: (
    //         <FormattedMessage
    //             defaultMessage="途道机器人"
    //             description="Collaborator for the 'icbUsb' extension"
    //             id="gui.extension.icbUsb.collaborator"
    //         />
    //     ),
    //     iconURL: icbUsbIconURL,
    //     insetIconURL: icbUsbInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="连上USB线就能控制的机器人"
    //             description="Description for the 'icbUsb' extension"
    //             id="gui.extension.icbUsb.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: true,
    //     connectionIconURL: icbUsbConnectionIconURL,
    //     connectionSmallIconURL: icbUsbConnectionSmallIconURL,
    //     connectionTipIconURL: icbUsbConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="正在连接"
    //             description="Message to help people connect to their icbUsb."
    //             id="gui.extension.icbUsb.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://www.makerzoid.com/'
    // },
    {
        name: (
            <FormattedMessage
                defaultMessage="途道遥控器"
                description="Name for the 'gibbon' extension"
                id="gui.extension.gibbon.name"
            />
        ),
        extensionId: 'gibbon',
        collaborator: (
            <FormattedMessage
                defaultMessage="途道机器人"
                description="Collaborator for the 'gibbon' extension"
                id="gui.extension.gibbon.collaborator"
            />
        ),
        iconURL: gibbonIconURL,
        insetIconURL: gibbonInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="可编程的遥控器"
                description="Description for the 'gibbon' extension"
                id="gui.extension.gibbon.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gibbonConnectionIconURL,
        connectionSmallIconURL: gibbonConnectionSmallIconURL,
        connectionTipIconURL: gibbonConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="正在连接"
                description="Message to help people connect to their gibbon."
                id="gui.extension.gibbon.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="智控板2.0（USB模式）"
    //             description="Name for the 'icb02Usb' extension"
    //             id="gui.extension.icb02Usb.name"
    //         />
    //     ),
    //     extensionId: 'icb02Usb',
    //     collaborator: (
    //         <FormattedMessage
    //             defaultMessage="途道机器人"
    //             description="Collaborator for the 'icb02Usb' extension"
    //             id="gui.extension.icb02Usb.collaborator"
    //         />
    //     ),
    //     iconURL: icb02UsbIconURL,
    //     insetIconURL: icb02UsbInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="连上USB线就能控制的机器人"
    //             description="Description for the 'icb02Usb' extension"
    //             id="gui.extension.icb02Usb.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: true,
    //     connectionIconURL: icb02UsbConnectionIconURL,
    //     connectionSmallIconURL: icb02UsbConnectionSmallIconURL,
    //     connectionTipIconURL: icb02UsbConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="正在连接"
    //             description="Message to help people connect to their icb02Usb."
    //             id="gui.extension.icb02Usb.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://www.makerzoid.com/'
    // },
    {
        name: (
            <FormattedMessage
                defaultMessage="Superbot Master Premium (Bluetooth Mode)"
                description="Name for the 'SuperbotMiniBle' extension"
                id="gui.extension.superbotMiniBle.name"
            />
        ),
        extensionId: 'superbotMiniBle',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'SuperbotMiniBle' extension"
                id="gui.extension.superbotMiniBle.collaborator"
            />
        ),
        iconURL: superbotMiniBleIconURL,
        insetIconURL: superbotMiniBleInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything you want."
                description="Description for the 'SuperbotMiniBle' extension"
                id="gui.extension.superbotMiniBle.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: superbotMiniBleConnectionIconURL,
        connectionSmallIconURL: superbotMiniBleConnectionSmallIconURL,
        connectionTipIconURL: superbotMiniBleConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Superbot."
                id="gui.extension.superbotMiniBle.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Superbot Master Premium (USB Mode)"
                description="Name for the 'SuperbotMiniUsb' extension"
                id="gui.extension.superbotMiniUsb.name"
            />
        ),
        extensionId: 'superbotMiniUsb',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'Superbot' extension"
                id="gui.extension.superbotMiniUsb.collaborator"
            />
        ),
        iconURL: superbotMiniUsbIconURL,
        insetIconURL: superbotMiniUsbInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A robot that can be controlled by connecting a USB cable."
                description="Description for the 'Superbot' extension"
                id="gui.extension.superbotMiniUsb.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: superbotMiniUsbConnectionIconURL,
        connectionSmallIconURL: superbotMiniUsbConnectionSmallIconURL,
        connectionTipIconURL: superbotMiniUsbConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Superbot."
                id="gui.extension.superbotMiniUsb.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
];
